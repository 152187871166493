<template>
    <dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ genre.name }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.genres.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name" v-model="genre.name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="genre.created_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="handleSave">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </dashboard-layout>
</template>

<script>
import Genre from "@/models/Genre";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "users-show",
    components: {DashboardLayout},
    data: function () {
        return {
            genre: {}
        }
    },
    async mounted() {
        const genreId = this.$route.params.id
        if (genreId) {
            this.genre = await Genre.find(genreId)
        }
    },
    methods: {
        async handleSave() {
            let genre = new Genre(this.genre)
            genre = await genre.save()
            this.genre = genre
            await this.$router.push({name: 'admin.genres.show', params: {id: genre.id}})
        }
    }
}
</script>

<style scoped>

</style>
